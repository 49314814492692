<template>
  <section class="header">
    <div>
      <div class="nome-e-logo">
        <img src="../../../public/Logotipos.svg"/>
        <span class="nome-da-empresa">{{ estabelecimento }}</span>
      </div>
      <h1 data-group="titulo-relatorio">{{ titulo }}</h1>
    </div>
    <div class="header-parte-esquerda">
      <span>Data de emissão: {{ this.dataEmissao }}</span>
      <span>Página: {{ pagina }} de {{ totalPaginas }}</span>
    </div>
  </section>
  <slot></slot>
</template>

<script>

export default {
  data () {
    return {
      dataEmissao: new Date().toLocaleString()
    }
  },
  props: {
    titulo: String,
    pagina: Number,
    totalPaginas: Number,
    estabelecimento: String
  }
}
</script>

<style>
  .header {
    display: flex;
    justify-content: space-between;
    align-items: flex-start;
    align-self: stretch;
    padding: 0.25rem 0 0 0;
  }

  .nome-da-empresa {
    font-size: 20px
  }

  .nome-e-logo {
    display: flex;
    flex-direction: row;
    align-items: center;
    gap: 3px;
  }

  .header-parte-esquerda {
    text-align: end;
    display: flex;
    flex-direction: column;
    font-size: 10px;
  }

</style>
