import jsonLog from '../json/tipoLogradouro.json'

export const utilsRelatorio = {

  formatarGenero (genero) {
    if (genero === 'F') {
      return 'Feminino'
    } else {
      return 'Masculino'
    }
  },

  formatarAutodeclaracao (autoDeclaracao) {
    switch (autoDeclaracao) {
      case 1:
        return 'Indígena'
      case 2:
        return 'Branca'
      case 3:
        return 'Negra'
      case 6:
        return 'Amarela'
      case 8:
        return 'Parda'
      default:
        return 'Não Informado'
    }
  },

  isDataValida (value) {
    const instaciaDeUmaData = value instanceof Date && !isNaN(value)
    const dentroDePeriodoValido = (value >= new Date('1900-01-01')) && (value <= new Date('9999-12-31'))
    return instaciaDeUmaData && dentroDePeriodoValido
  },

  formatarData (data) {
    const locale = window.navigator.language

    if (data && this.isDataValida(new Date(data)) && locale) {
      const dataAFormatar = new Date(data)

      const dataLocal = new Date(dataAFormatar.getTime() + dataAFormatar.getTimezoneOffset() * 60000)

      const options = { year: 'numeric', month: '2-digit', day: '2-digit' }
      const dataFormatada = dataLocal.toLocaleDateString(locale, options)
      return dataFormatada
    } else {
      return data
    }
  },

  formatarEstadoCivil (estadoCivil) {
    switch (estadoCivil) {
      case 'C':
        return 'Casado'
      case 'V':
        return 'Viúvo'
      case 'S':
        return 'Solteiro'
      case 'D':
        return 'Divorciado'
      case 'U':
        return 'União Estável'
      case 'O':
        return 'Outros'
      default:
        return 'Não informado'
    }
  },

  formatarGrauInstrucao (grauInstrucao) {
    switch (grauInstrucao) {
      case 1:
        return 'Analfabeto'
      case 2:
        return 'Até à 4a série incompleta do ensino fundamental'
      case 3:
        return '4a série completa do ensino fundamental'
      case 4:
        return 'Da 5a à 8a série do ensino fundamental'
      case 5:
        return 'Ensino fundamental completo'
      case 6:
        return 'Ensino médio incompleto'
      case 7:
        return 'Ensino médio completo'
      case 8:
        return 'Educação Superior incompleta'
      case 9:
        return 'Educação Superior completa'
      case 10:
        return 'Pós Graduação'
      case 11:
        return 'Mestrado'
      case 12:
        return 'Doutorado'
      default:
        return 'Não informado'
    }
  },

  formatarDeficiente (deficiente) {
    if (deficiente === true) {
      return 'Sim'
    }
    return 'Não'
  },

  formatarTipoDeficiencia (tipoDeficiencia) {
    switch (parseInt(tipoDeficiencia)) {
      case 1:
        return 'Motora'
      case 2:
        return 'Visual'
      case 3:
        return 'Auditiva'
      case 4:
        return 'Mental'
      default:
        return ''
    }
  },

  formatarTelefone (telefone) {
    if (!telefone) return ''

    telefone = telefone.toString().replace(/\D/g, '')

    if (telefone.length === 8 || telefone.length === 9) {
      return `${telefone.slice(0, telefone.length - 4)}-${telefone.slice(telefone.length - 4)}`
    } else if (telefone.length === 10 || telefone.length === 11) {
      return `(${telefone.slice(0, 2)}) ${telefone.slice(2, telefone.length - 4)}-${telefone.slice(telefone.length - 4)}`
    } else {
      return telefone
    }
  },

  formatarCpf (cpf) {
    if (!cpf) return ''
    cpf = cpf.toString()

    cpf = cpf.replace(/\D/g, '')

    if (cpf.length === 11) {
      return `${cpf.substr(0, 3)}.${cpf.substr(3, 3)}.${cpf.substr(6, 3)}-${cpf.substr(9)}`
    } else {
      return cpf
    }
  },

  formatarPais (pais) {
    switch (parseInt(pais)) {
      case 13:
        return 'Afeganistão'
      case 17:
        return 'República da Albânia'
      case 23:
        return 'Alemanha'
      case 31:
        return 'Burkina Faso'
      case 37:
        return 'Andorra'
      case 40:
        return 'Angola'
      case 41:
        return 'Anguilla'
      case 43:
        return 'Antígua e Barbuda'
      case 47:
        return 'Antilhas Holandesas'
      case 53:
        return 'Arábia Saudita'
      case 59:
        return 'Argélia'
      case 63:
        return 'Argentina'
      case 64:
        return 'República da Armênia'
      case 65:
        return 'Aruba'
      case 69:
        return 'Austrália'
      case 72:
        return 'Áustria'
      case 73:
        return 'República do Azerbaijão'
      case 77:
        return 'Ilhas Bahamas'
      case 80:
        return 'Ilhas Bahrein'
      case 81:
        return 'Bangladesh'
      case 83:
        return 'Barbados'
      case 85:
        return 'República da Belarus'
      case 87:
        return 'Bélgica'
      case 88:
        return 'Belize'
      case 90:
        return 'Bermudas'
      case 93:
        return 'Mianmar (Birmânia)'
      case 97:
        return 'Bolívia'
      case 98:
        return 'Bósnia e Herzegovina'
      case 101:
        return 'Bostuana'
      case 105:
        return 'Brasil'
      case 108:
        return 'Brunei'
      case 111:
        return 'República da Bulgária'
      case 115:
        return 'Burundi'
      case 119:
        return 'Butão'
      case 127:
        return 'República de Cabo Verde'
      case 137:
        return 'Ilhas Cayman'
      case 141:
        return 'Camboja'
      case 145:
        return 'Camarões'
      case 149:
        return 'Canadá'
      case 150:
        return 'Ilha do Canal Jersey'
      case 151:
        return 'Ilhas Canárias'
      case 153:
        return 'República do Cazaquistão'
      case 154:
        return 'Catar'
      case 158:
        return 'Chile'
      case 160:
        return 'Chile'
      case 161:
        return 'Taiwan'
      case 163:
        return 'Chipre'
      case 165:
        return 'Ilhas Cocos (Keeling)'
      case 173:
        return 'Ilhas Comores'
      case 177:
        return 'Congo'
      case 183:
        return 'Ilhas Cook'
      case 187:
        return 'República Popular da Coreia'
      case 190:
        return 'República da Coreia'
      case 193:
        return 'Costa do Marfim'
      case 195:
        return 'Croácia'
      case 196:
        return 'Costa Rica'
      case 198:
        return 'Kuwait'
      case 199:
        return 'Cuba'
      case 229:
        return 'Benin'
      case 232:
        return 'Dinamarca'
      case 235:
        return 'Ilha Dominica'
      case 237:
        return 'Dubai'
      case 239:
        return 'Equador'
      case 240:
        return 'Egito'
      case 243:
        return 'Eritreia'
      case 244:
        return 'Emirados Árabes Unidos'
      case 245:
        return 'Espanha'
      case 246:
        return 'Eslovênia'
      case 247:
        return 'Eslováquia'
      case 249:
        return 'Estados Unidos'
      case 251:
        return 'Estônia'
      case 253:
        return 'Etiópia'
      case 255:
        return 'Ilhas Falkland (Ilhas Malvinas)'
      case 259:
        return 'Ilhas Feroe'
      case 267:
        return 'Filipinas'
      case 271:
        return 'Finlândia'
      case 275:
        return 'França'
      case 281:
        return 'Gabão'
      case 285:
        return 'Gâmbia'
      case 289:
        return 'Gana'
      case 291:
        return 'Geórgia'
      case 293:
        return 'Gibraltar'
      case 297:
        return 'Granada'
      case 301:
        return 'Grécia'
      case 305:
        return 'Groenlândia'
      case 309:
        return 'Guadalupe'
      case 313:
        return 'Guam'
      case 317:
        return 'Guatemala'
      case 325:
        return 'Guiana Francesa'
      case 329:
        return 'Guiné'
      case 331:
        return 'Guiné Equatorial'
      case 334:
        return 'Guiné-Bissau'
      case 337:
        return 'Guiana'
      case 341:
        return 'Haiti'
      case 345:
        return 'Honduras'
      case 351:
        return 'Hong Kong'
      case 355:
        return 'Hungria'
      case 357:
        return 'Iemen'
      case 359:
        return 'Ilha de Man'
      case 361:
        return 'Índia'
      case 365:
        return 'Indonésia'
      case 367:
        return 'Inglaterra'
      case 369:
        return 'Iraque'
      case 372:
        return 'República Islâmica do Irã'
      case 375:
        return 'Irlanda'
      case 379:
        return 'Islândia'
      case 383:
        return 'Israel'
      case 386:
        return 'Itália'
      case 388:
        return 'Iugoslávia'
      case 391:
        return 'Jamaica'
      case 396:
        return 'Ilhas Johnston'
      case 399:
        return 'Japão'
      case 403:
        return 'Jordânia'
      case 411:
        return 'Kiribati'
      case 420:
        return 'Laos'
      case 423:
        return 'Ilhas Lebuan'
      case 426:
        return 'Lesoto'
      case 427:
        return 'Letônia'
      case 431:
        return 'Líbano'
      case 434:
        return 'Libéria'
      case 438:
        return 'Líbia'
      case 440:
        return 'Liechtenstein'
      case 442:
        return 'Lituânia'
      case 445:
        return 'Luxemburgo'
      case 447:
        return 'Macau'
      case 449:
        return 'Macedônia do Norte'
      case 450:
        return 'Madagascar'
      case 452:
        return 'Ilha da Madeira'
      case 455:
        return 'Malásia'
      case 458:
        return 'Malavi'
      case 461:
        return 'Maldivas'
      case 464:
        return 'Mali'
      case 467:
        return 'Malta'
      case 472:
        return 'Marianas do Norte'
      case 474:
        return 'Marrocos'
      case 476:
        return 'Ilhas Marshall'
      case 477:
        return 'Martinica'
      case 485:
        return 'Ilhas Maurício'
      case 488:
        return 'Mauritânia'
      case 490:
        return 'Ilhas Midway'
      case 493:
        return 'México'
      case 494:
        return 'Moldávia'
      case 495:
        return 'Mônaco'
      case 497:
        return 'Mongólia'
      case 498:
        return 'Montenegro'
      case 499:
        return 'Micronésia'
      case 501:
        return 'Ilhas Montserrar'
      case 505:
        return 'Moçambique'
      case 507:
        return 'Namíbia'
      case 508:
        return 'Nauru'
      case 511:
        return 'Ilha Christmas'
      case 517:
        return 'Nepal'
      case 521:
        return 'Nicarágua'
      case 525:
        return 'Níger'
      case 527:
        return 'Nigéria'
      case 531:
        return 'Ilha Niue'
      case 535:
        return 'Ilha Norfolk'
      case 538:
        return 'Noruega'
      case 542:
        return 'Nova Caledônia'
      case 545:
        return 'Papua-Nova Guiné'
      case 548:
        return 'Nova Zelândia'
      case 551:
        return 'Vanuatu'
      case 556:
        return 'Omã'
      case 563:
        return 'Ilhas do Pacífico (Administração dos EUA)'
      case 566:
        return 'Ilhas do Pacífico (Possessão dos EUA)'
      case 573:
        return 'Países Baixos (Holanda)'
      case 575:
        return 'Palau'
      case 576:
        return 'Paquistão'
      case 578:
        return 'Palestina'
      case 580:
        return 'Panamá'
      case 586:
        return 'Paraguai'
      case 589:
        return 'Peru'
      case 593:
        return 'Ilha Pitcairn'
      case 599:
        return 'Polinésia Francesa'
      case 603:
        return 'Polônia'
      case 607:
        return 'Portugal'
      case 611:
        return 'Porto Rico'
      case 623:
        return 'Quênia'
      case 625:
        return 'Quirguiz'
      case 628:
        return 'Reino Unido'
      case 640:
        return 'República Centro-Africana'
      case 647:
        return 'República Dominicana'
      case 660:
        return 'Ilha Reunião'
      case 665:
        return 'Zimbaque'
      case 670:
        return 'Romênia'
      case 675:
        return 'Ruanda'
      case 676:
        return 'Rússia'
      case 677:
        return 'Ilhas Salomão'
      case 678:
        return 'Saint Kitts e Nevis'
      case 685:
        return 'Saara Ocidental'
      case 687:
        return 'El Salvador'
      case 690:
        return 'Samoa'
      case 691:
        return 'Samoa Americana'
      case 695:
        return 'Ilhas São Cristóvão e Neves'
      case 697:
        return 'San Marino'
      case 700:
        return 'São Pedro e Miquelon'
      case 705:
        return 'São Vicente e Granadinas'
      case 710:
        return 'Santa Helena'
      case 715:
        return 'Santa Lúcia'
      case 720:
        return 'Ilhas São Tomé e Príncipe'
      case 728:
        return 'Senegal'
      case 731:
        return 'Seychelles'
      case 735:
        return 'Serra Leoa'
      case 737:
        return 'Singapura'
      case 741:
        return 'Síria'
      case 744:
        return 'Somália'
      case 748:
        return 'Sri Lanka'
      case 750:
        return 'Suazilândia'
      case 754:
        return 'África do Sul'
      case 756:
        return 'Suécia'
      case 767:
        return 'Suiça'
      case 770:
        return 'Suriname'
      case 772:
        return 'Tajiquistão'
      case 776:
        return 'Tailândia'
      case 780:
        return 'Tanzânia'
      case 782:
        return 'Território Britânico do Oceano Índico'
      case 783:
        return 'Djibouti'
      case 788:
        return 'Chade'
      case 791:
        return 'República Tcheca'
      case 795:
        return 'Timor Leste'
      case 800:
        return 'Togo'
      case 805:
        return 'Ilhas Toquelau'
      case 810:
        return 'Tonga'
      case 815:
        return 'Trindad e Tobago'
      case 820:
        return 'Tunísia'
      case 823:
        return 'Ilhas Turcas e Caicos'
      case 824:
        return 'Turcomenistão'
      case 827:
        return 'Turquia'
      case 828:
        return 'Tuvalu'
      case 831:
        return 'Ucrânia'
      case 833:
        return 'Uganda'
      case 845:
        return 'Uruguai'
      case 847:
        return 'Uzbequistão'
      case 848:
        return 'Vaticano'
      case 850:
        return 'Venezuela'
      case 858:
        return 'Vietnã'
      case 863:
        return 'Ilhas Virgens (Britânicas)'
      case 866:
        return 'Ilhas Virgens (EUA)'
      case 870:
        return 'Fiji'
      case 873:
        return 'Ilha Wake'
      case 875:
        return 'Ilhas Wallis e Futuna'
      case 888:
        return 'República do Congo'
      case 890:
        return 'Zamíbia'
      case 895:
        return 'Zona do Canal do Panamá'
      case 990:
        return 'Provisão de Navios e Aeronaves'
      case 994:
        return 'A Designar'
      case 995:
        return 'Bancos Centrais'
      case 997:
        return 'Organizações Internacionais'
      case 998:
        return 'Delegação Especial da Palestina'
      case 999:
        return 'Outros'
    }
  },

  formatarNacionalidade (nacionalidade) {
    switch (parseInt(nacionalidade)) {
      case 10:
        return 'Brasileiro'
      case 20:
        return 'Naturalizado Brasileiro'
      case 21:
        return 'Argentino'
      case 22:
        return 'Boliviano'
      case 23:
        return 'Chileno'
      case 24:
        return 'Paraguaio'
      case 25:
        return 'Uruguaio'
      case 26:
        return 'Venezuelano'
      case 27:
        return 'Colombiano'
      case 28:
        return 'Peruano'
      case 29:
        return 'Equatoriano'
      case 30:
        return 'Alemão'
      case 31:
        return 'Belga'
      case 32:
        return 'Britânico'
      case 34:
        return 'Canadense'
      case 35:
        return 'Espanhol'
      case 36:
        return 'Norte-Americano (EUA)'
      case 37:
        return 'Francês'
      case 38:
        return 'Suíço'
      case 39:
        return 'Italiano'
      case 40:
        return 'Haitiano'
      case 41:
        return 'Japonês'
      case 42:
        return 'Chinês'
      case 43:
        return 'Coreano'
      case 44:
        return 'Russo'
      case 45:
        return 'Português'
      case 46:
        return 'Paquistanês'
      case 47:
        return 'Indiano'
      case 48:
        return 'Outros latino-americanos'
      case 49:
        return 'Outros Asiáticos'
      case 50:
        return 'Bengalês'
      case 51:
        return 'Outros Europeu'
      case 60:
        return 'Angolano'
      case 61:
        return 'Congolês'
      case 62:
        return 'Sul-Africano'
      case 63:
        return 'Ganês'
      case 64:
        return 'Senegalês'
      case 70:
        return 'Outros Africanos'
      case 80:
        return 'Indiano'
    }
  },

  formatarQualificacao (qualificacao) {
    switch (parseInt(qualificacao)) {
      case 1:
        return 'Procurador'
      case 2:
        return 'Curador'
      case 3:
        return 'Mãe'
      case 4:
        return 'Pai'
      case 5:
        return 'Tutor'
      case 6:
        return 'Outro'
      default:
        return ''
    }
  },

  mascaraCNPJ (cnpj) {
    if (!cnpj) return ''
    cnpj = cnpj.toString()

    cnpj = cnpj.replace(/\D/g, '')

    if (cnpj.length === 14) {
      return `${cnpj.substr(0, 2)}.${cnpj.substr(2, 3)}.${cnpj.substr(5, 3)}/${cnpj.substr(8, 4)}-${cnpj.substr(12)}`
    } else {
      return cnpj
    }
  },

  buscaTipoLogradouro (tipo) {
    const tiposLogradouros = jsonLog.data
    const listaTiposLogradouros = JSON.parse(
      JSON.stringify(tiposLogradouros)
    )
    const tipoEncontrado = {
      abv: null,
      nome: null
    }
    Object.assign(
      tipoEncontrado,
      listaTiposLogradouros.find((cbo) => cbo.abv === tipo)
    )
    return tipoEncontrado.nome
  }
}
