<template>
  <Relatorio v-if="this.status === ''" id="relatorios">
    <RelatorioFolha v-for="(relatorio, index) in this.relatorios" :key="index">
      <div class="relatorio">
      <RelatorioImpressao
        titulo="Relatório de Sócios"
        :pagina="index + 1"
        :totalPaginas="relatorios.length"
        :estabelecimento="estabelecimento"/>
      <section class="wrapper">
        <h2 data-group="relatorio-pf-pj-subtitulo-socio">Sócio</h2>
        <div class="dados-da-section">
          <div class="column">
            <div class="row" :data-group="`relatorio-pf-pj-socio-codigo-${index}`">Código: {{ relatorio.cdsocio }}</div>
            <div class="row" :data-group="`relatorio-pf-pj-socio-nome-${index}`">Nome: {{ relatorio.nmsocio }}</div>
          </div>
          <div class="column">
            <div class="row" :data-group="`relatorio-pf-socio-nrcpf-${index}`" v-if="relatorio.nrcpf.length === 11">CPF: {{ formatarCPF(relatorio.nrcpf) }}</div>
            <div class="row" :data-group="`relatorio-pj-socio-nrcnpj-${index}`" v-else>CNPJ: {{ mascaraCNPJ(relatorio.nrcpf) }}</div>
          </div>
        </div>
      </section>

      <section class="wrapper">
        <h2 data-group="relatorio-pf-pj-subtitulo-endereco">Endereço</h2>
        <div class="dados-da-section">
          <div class="column">
            <div class="row" :data-group="`relatorio-pf-pj-endereco-cep-${index}`">CEP: {{ relatorio.nrcep }}</div>
            <div class="row" :data-group="`relatorio-pf-pj-endereco-logradouro-${index}`">Logradouro: {{ buscaTipoLogradouro(relatorio.endereco_tipo) }} {{ relatorio.dsendereco }}</div>
            <div class="row" :data-group="`relatorio-pf-pj-endereco-numero-${index}`">Número: {{ relatorio.endereco_numero }}</div>
            <div class="row" :data-group="`relatorio-pf-pj-endereco-complemento-${index}`">Complemento: {{ relatorio.endereco_complemento }}</div>
          </div>
          <div class="column">
            <div class="row" :data-group="`relatorio-pf-pj-endereco-bairro-${index}`">Bairro: {{ relatorio.nmbairro }}</div>
            <div class="row" :data-group="`relatorio-pf-pj-endereco-municipio-${index}`">Município: {{ relatorio.nmcidade }}</div>
            <div class="row" :data-group="`relatorio-pf-pj-endereco-uf-${index}`">UF: {{ relatorio.cduf }}</div>
            <div class="row" :data-group="`relatorio-pf-pj-endereco-pais-${index}`">País: {{ formatarPais(relatorio.endereco_pais_codigo) }}</div>
          </div>
        </div>
      </section>

      <section class="wrapper">
        <h2 data-group="relatorio-pf-pj-subtitulo-contato">Contato</h2>
        <div class="dados-da-section">
          <div class="column">
            <div class="row" :data-group="`relatorio-pf-pj-contato-telefone-${index}`">Telefone: {{ formatarTelefone(relatorio.nrtelefone) }}</div>
          </div>
          <div class="column">
            <div class="row" :data-group="`relatorio-pf-pj-contato-email-${index}`">E-mail: {{ relatorio.nmemail }}</div>
          </div>
        </div>
      </section>

      <div class="relatorio campos-socio-fisico" v-if="relatorio.nrcpf.length === 11">
        <section class="wrapper">
          <h2 data-group="relatorio-pf-subtitulo-identidade">Identidade</h2>
          <div class="dados-da-section">
            <div class="column">
              <div class="row" :data-group="`relatorio-pf-identidade-nridentidade-${index}`">Número: {{ relatorio.nridentidade }}</div>
              <div class="row" :data-group="`relatorio-pf-identidade-nmorgaoexp-${index}`">Órgão expedidor: {{ relatorio.nmorgaoexp }}</div>
            </div>
            <div class="column">
              <div class="row" :data-group="`relatorio-pf-identidade-data-expedicao-${index}`">Data de expedição: {{ formatarData(relatorio.dtemissaorg) }}</div>
            </div>
          </div>
        </section>

        <section class="wrapper">
          <h2 data-group="relatorio-pf-subtitulo-carteira-de-motorista">Carteira de motorista</h2>
          <div class="dados-da-section">
            <div class="column">
              <div class="row" :data-group="`relatorio-pf-carteira-de-motorista-numero-${index}`">Número: {{ relatorio.cnh_numero }}</div>
              <div class="row" :data-group="`relatorio-pf-carteira-de-motorista-orgao-expedidor-${index}`">Órgão expedidor: {{ relatorio.cnh_orgao_emissor }}</div>
            </div>
            <div class="column">
              <div class="row" :data-group="`relatorio-pf-carteira-de-motorista-data-expedicao-${index}`">Data de expedição: {{ formatarData(relatorio.cnh_expedicao) }}</div>
              <div class="row" :data-group="`relatorio-pf-carteira-de-motorista-data-validade-${index}`">Data de validade: {{ formatarData(relatorio.cnh_data_validade) }}</div>
            </div>
          </div>
        </section>

        <section class="wrapper">
          <h2 data-group="relatorio-pf-subtitulo-carteira-de-trabalho">Carteira de trabalho</h2>
          <div class="dados-da-section">
            <div class="column">
              <div class="row" :data-group="`relatorio-pf-carteira-de-trabalho-numero-${index}`">Número: {{ relatorio.ctps_numero }}</div>
              <div class="row" :data-group="`relatorio-pf-carteira-de-trabalho-serie-${index}`">Série: {{ relatorio.ctps_serie }}</div>
              <div class="row" :data-group="`relatorio-pf-carteira-de-trabalho-uf-${index}`">UF da CTPS: {{ relatorio.ctps_uf }}</div>
            </div>
            <div class="column">
              <div class="row" :data-group="`relatorio-pf-carteira-de-trabalho-cbo-${index}`">CBO 2002: {{ relatorio.nrcbonovo }}</div>
              <div class="row" :data-group="`relatorio-pf-carteira-de-trabalho-deficiente-${index}`">Deficiente: {{ formatarDeficiente(relatorio.deficiente) }}</div>
              <div class="row" :data-group="`relatorio-pf-carteira-de-trabalho-tipo-deficiencia-${index}`">Tipo de deficiência: {{ formatarTipoDeficiencia(relatorio.deficiente_tipo) }}</div>
            </div>
          </div>
        </section>

        <section class="wrapper">
          <h2 data-group="relatorio-pf-subtitulo-estado-civil">Estado civil</h2>
          <div class="dados-da-section">
            <div class="column">
              <div class="row" :data-group="`relatorio-pf-estado-civil-situacao-${index}`">Situação: {{ formatarEstadoCivil(relatorio.dsestadocivil) }}</div>
              <div class="row" :data-group="`relatorio-pf-estado-civil-regime-matrimonio-${index}`">Regime de matrimônio: {{ relatorio.regimematrimonio }}</div>
            </div>
            <div class="column">
              <div class="row" :data-group="`relatorio-pf-estado-civil-numero-dependentes-${index}`">Número de dependentes: {{ relatorio.nrdependentes }}</div>
            </div>
          </div>
        </section>

        <section class="wrapper">
          <h2 data-group="relatorio-pf-subtitulo-dados-de-nascimento">Dados do nascimento</h2>
          <div class="dados-da-section">
            <div class="column">
              <div class="row" :data-group="`relatorio-pf-dados-de-nascimento-data-${index}`">Data: {{ formatarData(relatorio.dtnascimento) }}</div>
              <div class="row" :data-group="`relatorio-pf-dados-de-nascimento-pais-${index}`">País: {{ formatarPais(relatorio.pais_nascimento_codigo) }}</div>
            </div>
            <div class="column">
              <div class="row" :data-group="`relatorio-pf-dados-de-nascimento-uf-${index}`">UF: {{ relatorio.uf_municipio_nascimento }}</div>
              <div class="row" :data-group="`relatorio-pf-dados-de-nascimento-municipio-${index}`">Município: {{ relatorio.nome_municipio }}
            </div>
            </div>
          </div>
        </section>

        <section class="wrapper">
          <h2 data-group="relatorio-pf-subtitulo-e-social">E-Social</h2>
          <div class="dados-da-section">
            <div class="column">
              <div class="row" :data-group="`relatorio-pf-e-social-sexo-${index}`">Sexo: {{ formatarGenero(relatorio.sexo) }}</div>
              <div class="row" :data-group="`relatorio-pf-e-social-raca-cor-${index}`">Raça/cor: {{ formatarAutodeclaracao(relatorio.raca_cor) }}</div>
              <div class="row" :data-group="`relatorio-pf-e-social-nome-mae-${index}`">Nome da mãe: {{ relatorio.mae_nome }}</div>
              <div class="row" :data-group="`relatorio-pf-e-social-nome-pai-${index}`">Nome do pai: {{ relatorio.pai_nome }}</div>
            </div>
            <div class="column">
              <div class="row" :data-group="`relatorio-pf-e-social-grau-instrucao-${index}`">Grau de instrução: {{ formatarGrauInstrucao(relatorio.codigo_instrucao) }}</div>
              <div class="row" :data-group="`relatorio-pf-e-social-nacionalidade-${index}`">Nacionalidade: {{ formatarNacionalidade(relatorio.cdnacionalidade) }}</div>
              <div class="row" :data-group="`relatorio-pf-e-social-profissao-${index}`">Profissão: {{ relatorio.nmprofissao }}</div>
            </div>
          </div>
        </section>

        <section class="wrapper">
        <h2 data-group="relatorio-pf-subtitulo-observacoes">Observações</h2>
        <div class="dados-da-section">
              <div class="observacoes" :data-group="`relatorio-pf-pj-observacoes-${index}`">
                {{ relatorio.dsobs }}
              </div>
        </div>
      </section>

      </div>

      <div v-else class="relatorio campos-socio-juridico">
        <section class="wrapper">
          <h2 data-group="relatorio-pj-subtitulo-documentos">Documentos</h2>
          <div class="dados-da-section">
            <div class="column">
              <div class="row" :data-group="`relatorio-pj-documentos-inscricao-estadual-${index}`">Inscrição estadual: {{ relatorio.nridentidade }}</div>
              <div class="row" :data-group="`relatorio-pj-documentos-nome-responsavel-${index}`">Nome do responsável: {{ relatorio.nmresponsavel }}</div>
            </div>
            <div class="column">
              <div class="row" :data-group="`relatorio-pj-documentos-qualificacao-representante-legal-${index}`">Qualificação do representante legal: {{ formatarQualificacao(relatorio.strepresentantedipj) }}</div>
              <div class="row" :data-group="`relatorio-pj-documentos-cpf-responsavel-${index}`">CPF do responsável: {{ formatarCPF(relatorio.nrcpfresponsavel) }}</div>
            </div>
          </div>
        </section>

        <section class="wrapper">
        <h2 data-group="relatorio-pj-subtitulo-observacoes">Observações</h2>
        <div class="dados-da-section">
              <div class="observacoes" :data-group="`relatorio-pf-pj-observacoes-${index}`">
                {{ relatorio.dsobs }}
              </div>
        </div>
      </section>

      </div>
      </div>
    </RelatorioFolha>
  </Relatorio>

  <section v-else>
    <RelatorioLoading/>
  </section>

</template>

<script>

import { service } from '../../../service.js'
import { utilsRelatorio } from './utilsRelatorio'
import { wphdService } from '../../../WphdService.js'
import RelatorioImpressao from '@/components/Relatorio/Relatorio.Header.vue'
import RelatorioFolha from '@/components/Relatorio/Relatorio.Folha.vue'
import Relatorio from '@/components/Relatorio/Relatorio.vue'
import RelatorioLoading from '@/components/Relatorio/Relatorio.Loading.vue'

export default {

  name: 'RelatorioSocios',

  data () {
    return {
      estabelecimento: this.estabelecimento,
      status: 'is-empty',
      relatorios: [],
      ids: [],
      ordem: '',
      dataEmissao: new Date().toLocaleString()
    }
  },

  components: {
    RelatorioImpressao,
    RelatorioFolha,
    Relatorio,
    RelatorioLoading
  },

  methods: {

    formatarGenero (genero) {
      return utilsRelatorio.formatarGenero(genero)
    },

    formatarAutodeclaracao (autodeclaracao) {
      return utilsRelatorio.formatarAutodeclaracao(autodeclaracao)
    },

    formatarData (data) {
      return utilsRelatorio.formatarData(data)
    },

    formatarEstadoCivil (estadoCivil) {
      return utilsRelatorio.formatarEstadoCivil(estadoCivil)
    },

    formatarGrauInstrucao (grauInstrucao) {
      return utilsRelatorio.formatarGrauInstrucao(grauInstrucao)
    },

    formatarTipoDeficiencia (Tipodeficiencia) {
      return utilsRelatorio.formatarTipoDeficiencia(Tipodeficiencia)
    },

    formatarDeficiente (deficiente) {
      return utilsRelatorio.formatarDeficiente(deficiente)
    },

    formatarPais (pais) {
      return utilsRelatorio.formatarPais(pais)
    },

    formatarNacionalidade (nacionalidade) {
      return utilsRelatorio.formatarNacionalidade(nacionalidade)
    },

    formatarTelefone (telefone) {
      return utilsRelatorio.formatarTelefone(telefone)
    },

    formatarCPF (cpf) {
      return utilsRelatorio.formatarCpf(cpf)
    },

    formatarQualificacao (qualificacao) {
      return utilsRelatorio.formatarQualificacao(qualificacao)
    },

    mascaraCNPJ (CNPJ) {
      return utilsRelatorio.mascaraCNPJ(CNPJ)
    },

    buscaTipoLogradouro (tipo) {
      return utilsRelatorio.buscaTipoLogradouro(tipo)
    },

    async _carregarRelatorios () {
      this.status = 'is-loading'
      try {
        this.relatorios = await service.get(`/api/v1/socio/relatorio?ids=${this.ids}&orderBy=${this.ordem}`)
        this.status = ''
        this.exibirImpressaoEFecharAba()
      } catch (e) {
        this.status = 'is-danger'
      }
    },
    async _carregarInfContador () {
      this.status = 'is-loading'
      try {
        await wphdService.get('/api/v1/infcontador').then((obj) => {
          this.estabelecimento = obj.estabelecimento
        })
      } catch (e) {
        this.status = 'is-danger'
      }
    },

    exibirImpressaoEFecharAba () {
      setTimeout(() => {
        const relatorios = document.getElementById('relatorios')

        document.body.innerHTML = ''
        document.body.appendChild(relatorios)
        window.print()
        window.close()
      }, 500)
    }
  },

  created () {
    this.ids = this.$route.query.ids
    this.ordem = this.$route.query.ordem
    this._carregarRelatorios()
    this._carregarInfContador()
  }
}

</script>

<style>

@media print {

  .wrapper {
    align-self: stretch;
  }

  .dados-da-section {
    display: flex;
    align-items: flex-start;
    align-self: stretch;
  }

  .column,
  .row {
    display: flex;
    gap: 4px;
  }

  .column {
    flex-direction: column;
    align-items: flex-start;
    flex: 1;
    padding: 0px;
  }

  .row {
    align-items: center;
    font-size: 12px;
    font-weight: 400;
  }

  .observacoes {
    width: 100%;
    overflow: hidden !important;
    text-align: justify;
    text-overflow: ellipsis;
    word-break: break-word;
    font-size: 12px;
  }
}
</style>
