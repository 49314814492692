<template>
  <article class="folha" data-group="folha-relatorio">
  <div class="relatorio">
    <slot></slot>
  </div>
  </article>
</template>

<script>
export default {

}
</script>

<style>

.relatorio {
  display: none;
}

@media print {

  .folha {
    height: 100vh;
    width: 100%;
    page-break-after: always;
  }
    .relatorio {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    gap: 20px;
    padding: 0;
    width: 100%;
    height: 100%;
    page-break-inside: avoid;
    background-color: white;
    color: #000;
    font-family: 'Roboto', sans-serif;
  }
}
</style>
