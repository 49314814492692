<template>
  <section class="loading-relatorio" data-group="loading-relatorio">
  <div class="column is-4">
    Gerando relatório...
    <progress class="progress is-small is-primary" max="10"></progress>
  </div>
  </section>
</template>

<style>
  .loading-relatorio {
    width: 100%;
    height: 90vh;
    display: grid;
    place-items: center;
}
</style>
