<template>
  <section class="relatorios">
    <slot></slot>
  </section>
</template>

<script>
export default {
}
</script>

<style>
  .relatorios {
    display: none;
  }

  h1 {
    font-size: 18px;
    font-weight: 900;
  }

  h2 {
    font-size: 14px;
    font-weight: 700;
  }

@media print {
  * {
    overflow: visible !important;
  }

  .relatorios {
    display: block;
    width: 100%;
  }
}
</style>
